// Export Firebase dev configuration
export default {
  apiKey: 'AIzaSyCbJM20MA7ULb2CVwxgRBUBuIW3dVSRHAE',
  authDomain: 'fdesk-7.firebaseapp.com',
  projectId: 'fdesk-7',
  storageBucket: 'fdesk-7.appspot.com',
  messagingSenderId: '616316651070',
  appId: '1:616316651070:web:47bdd9719da3330949b36f',
  measurementId: 'G-P8R1WC0D2N'
}
