<template>
  <div id="app">
    <iframe 
      v-if="loupe === true"
      :src="loupeUrl"
      style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;"
    >
      Your browser doesn't support iframes
    </iframe>
    <b-carousel
      v-if="loupe === false"
      id="artwork-carousel"
      v-model="carousel"
      :interval="300000"
      :controls="false"
      :indicators="false"
      :fade="true"
      img-width="1920"
      img-height="1080"
      background="#ababab"
      style="text-shadow: 1px 1px 2px #333;"
    >
      <b-carousel-slide v-for="(art, artIndex) in artwork" :key="artIndex">
        <template #img>
          <img
            :src="art"
            alt="image"
            class="d-block img-fluid w-100"
          >
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
import { db, artworkStorage } from './config/firebase'
import { pusher } from './config/pusher'

export default {
  name: "App",
  components: {
  },
  data() {
    return {
      property: null,
      display: null,
      displayLoaded: false,
      displayUnsubscribe: function () {},
      loupe: false,
      loupeUrl: '',
      carousel: 0,
      artwork: [],
      urlParams: new URLSearchParams(document.location.search.substring(1))
    }
  },
  computed: {
    uuid () {
      return this.urlParams.get('uuid') || 'ArtworkDemo'
    },
    propCode () {
      return this.urlParams.get('property') || 'ABCD1234'
    },
    artworkChannel () {
      return this.display && this.display.artworkChannel ? this.display.artworkChannel : null
    }
  },
  watch: {
    artworkChannel () {
      if (this.artworkChannel && typeof this.artworkChannel === 'string' && this.displayLoaded === true) {
        this.getLoupe(this.artworkChannel)
      } else {
        this.getArtwork(true)
      }
    }
  },
  async mounted () {
    console.log('Query parameters.', {
      propCode: this.propCode,
      uuid: this.uuid
    })

    // Retrieve property information
    let propertyDoc

    try {
      propertyDoc = await db.collection('propertyCodes').doc(this.propCode.toUpperCase()).get()
    } catch (error) {
      console.error('Unable to retrieve property data from storage.', error.message)
      return
    }

    if (!propertyDoc || propertyDoc.exists !== true) {
      console.error('Unable to pull property information.')
      return
    }

    this.property = propertyDoc.data()
    console.log('Setting property data.', this.property)

    if (this.property === null) {
      console.error('Unable to pull property information.')
      return
    }

    // Retrieve display information
    await this.getDisplay()

    if (this.artworkChannel && typeof this.artworkChannel === 'string') {
      this.getLoupe(this.artworkChannel)
    } else {
      this.getArtwork()
    }

    this.subscribe()
    this.displayLoaded = true
  },
  methods: {
    subscribe () {
      pusher.subscribe(`${this.property.propId}-${this.uuid}-artwork`)

      pusher.bind('di-event', data => {
        if (Object.prototype.hasOwnProperty.call(data, 'reload') === true && data.reload === true) {
          this.getArtwork(true)
        }
      })
    },
    getLoupe (channel) {
      console.log('Retrieving Loupe stream...')
      this.loupe = true

      // Check artwork license
      let artworkLicense = 'demo'

      if (Object.prototype.hasOwnProperty.call(this.property, 'artworkLicense') === true && this.property.artworkLicense === true) {
        artworkLicense = 'www'
      }

      this.loupeUrl = 'https://' + artworkLicense + '.loupeart.com/stream/' + channel + '?present=true&safeStream=true'
    },
    getArtwork (reset = false) {
      console.log('Retrieving artwork...')
      this.loupe = false

      artworkStorage
      .child(this.property.propId)
      .child(this.uuid)
      .listAll()
      .then((files) => {
        if (files.items && files.items.length > 0) {
          if (reset === true) {
            this.artwork = []
          }

          files.items.forEach((item) => {
            item.getDownloadURL().then((url) => {
              this.artwork.push(url)
            }).catch((error) => {
              console.error(error.message)
            })
          })
        } else {
          this.artwork = []
        }
      }).catch((error) => {
        console.error(error.message)
        return
      })
    },
    async getDisplay () {
      // Retrieve Display Information
      return new Promise((resolve) => {
        var resolveOnce = () => {
          // eslint-disable-next-line no-useless-return
          resolveOnce = () => { return }
          resolve(true)
        }

        if (this.property.mgmtId && this.property.propId && !this.display) {
          console.log('Retrieving display info...')

          this.displayUnsubscribe = db.collection('management').doc(this.property.mgmtId)
            .collection('properties').doc(this.property.propId)
            .collection('displays').where('uuid', '==', this.uuid)
            .onSnapshot(async (displayDocs) => {
              if (displayDocs.size === 1) {
                this.display = Object.assign({ docId: displayDocs.docs[0].id }, displayDocs.docs[0].data())

                resolveOnce()
              } else {
                console.error('Unable to pull display information, or the display count is incorrect.', displayDocs.size)
                this.display = null

                resolveOnce()
              }
            })
        } else {
          console.log('Unable to retrieve the display info because the property information is invalid.')
          resolveOnce()
        }
      })
    }
  }
}
</script>

<style>
</style>
