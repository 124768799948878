import Pusher from 'pusher-js'

// Determine dev environment
const prodDomains = [
  'artwork.deskintelligence.com'
]
const isDev = prodDomains.includes(window.location.hostname) === false

// Initialize Pusher
const pusherKey = isDev === true ? '52e3ea9956e292f69844' : '73b9fd03f1ea7ffc6a43'

// Utilities
const pusher = new Pusher(pusherKey, {
  cluster: 'mt1'
})

// Log Pusher to console in development environment
if(isDev === true) {
  Pusher.logToConsole = true
}

// Export
export {
  pusher
}
