// Export Firebase dev configuration
export default {
  apiKey: 'AIzaSyAsrHfpjJdHx9-2NmBWAcfUHnyK6jODaHg',
  authDomain: 'fdesk-7-dev.firebaseapp.com',
  databaseURL: 'https://fdesk-7-dev.firebaseio.com',
  projectId: 'fdesk-7-dev',
  storageBucket: 'fdesk-7-dev.appspot.com',
  messagingSenderId: '878703714611',
  appId: '1:878703714611:web:059dc411b83cf095d69e46'
}
