import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

// Determine dev environment
const prodDomains = [
  'artwork.deskintelligence.com'
]
const isDev = prodDomains.includes(window.location.hostname) === false

// Initialize Firebase
let firebaseConfig
let storagePrefix = ''

if (isDev === true) {
  console.info('Connecting to dev project.')
  firebaseConfig = require('./firebase-dev')
  storagePrefix = '-dev'
} else {
  firebaseConfig = require('./firebase-prod')
}

firebase.initializeApp(firebaseConfig.default)

// Utilities
const db = firebase.firestore()

// Storage buckets
const artworkStorageBucket = firebase.app().storage(`gs://fdesk-7${storagePrefix}-artwork`)

// Collection references
const artworkCollection = db.collection('artwork')

// Storage references
const artworkStorage = artworkStorageBucket.ref()

// Export
export {
  db,
  artworkStorage,
  artworkCollection
}
